<template>
  <div class="sc-header" :style="{background: colors.header.bg, color: colors.header.text}">
    <slot>

      <img v-if="conversationParticipant" @click="backToConversationList"
           class="back-arrow-button back-arrow-button-color" :src="icons.arrowLeft.img" :alt="icons.arrowLeft.name"/>

      <img class="sc-header--img" :src="imageUrl" alt="" v-if="imageUrl" />

      <div class="sc-header--title"> {{currentTitle}} </div>

    </slot>
  </div>
</template>
<script>
import ArrowLeft from './assets/arrow-left-solid.svg';

export default {
  props: {
    icons:{
      type: Object,
      default: function () {
        return {
          arrowLeft:{
            img: ArrowLeft,
            name: 'arrow-left',
          },
        };
      }
    },
    imageUrl: {
      type: String,
      required: true
    },
    title: {
      type: String
    },
    onClose: {
      type: Function,
      required: true
    },
    colors: {
      type: Object,
      required: true
    },
    disableConversationListToggle: {
      type: Boolean,
      default: false
    },
    showCloseButton: {
      type: Boolean,
      default: false
    },
    conversationParticipant: {
      type: Object,
      default: null
    }
  },
  computed: {
    currentTitle() {
      if (this.conversationParticipant) {
        return this.conversationParticipant.name;
      } else {
        return this.title;
      }
    }
  },
  methods: {
    backToConversationList() {
      this.$emit('back-to-conversation-list');
    }
  }
};
</script>
<style scoped>
.sc-header {
  min-height: 75px;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  padding: 10px;
  box-shadow: 0 1px 4px rgba(0,0,0,.2);
  position: relative;
  box-sizing: border-box;
  display: flex;
}

.sc-header--img {
  border-radius: 50%;
  align-self: center;
  padding: 10px;
  width: 40px;
}

.back-arrow-button {
  align-self: center;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.back-arrow-button-color {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
}

.sc-header--title {
  align-self: center;
  padding: 10px;
  flex: 1;
  user-select: none;
  font-size: 20px;
}

.sc-header--close-button {
  width: 40px;
  align-self: center;
  height: 40px;
  margin-right: 10px;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 5px;
  margin-left: auto;
}

.sc-header--close-button:hover {
  box-shadow: 0px 2px 5px rgba(0.2, 0.2, 0.5, .1);
}

.sc-header--close-button img {
  width: 100%;
  height: 100%;
  padding: 13px;
  box-sizing: border-box;
}

@media (max-width: 450px) {
  .sc-header {
    border-radius: 0px;
  }
}
</style>
