<template>
    <launcher :participants="participants"
              :titleImageUrl="titleImageUrl"
              :onMessageWasSent="sendMessage"
              :newMessagesCount="newMessagesCount"
              :isOpen="isChatOpen"
              :close="closeChat"
              :icons="icons"
              :open="openChat"
              :showEmoji="false"
              :showFile="false"
              :showTypingIndicator="showTypingIndicator"
              :showLauncher="true"
              :showCloseButton="true"
              :colors="colors"
              :alwaysScrollToBottom="alwaysScrollToBottom"
              :messageStyling="messageStyling"
              :internalMode="internalMode"
              :currentAgent="currentAgent"
              @onType="handleOnType"
    />
</template>

<script>
    import Launcher from './vue-beautiful-chat/Launcher.vue';
    import CloseIcon from './vue-beautiful-chat/assets/close-icon.png';
    import OpenIcon from './vue-beautiful-chat/assets/logo-no-bg.png';
    import {mapGetters, mapMutations, mapActions} from 'vuex';

    export default {
        name: 'TmgChat',
        components: {
            Launcher
        },
        props: {
            newMessagesCount: {
                default: 0,
                required: false
            },
            siteConfig: {
                required: true
            },
            internalMode: {
                default: false,
                required: false
            },
            contacts: {
                required: false
            },
            currentAgent: {
                required: false
            }
        },
        data() {
            return {
                icons: {
                    open: {
                        img: OpenIcon,
                        name: 'default',
                    },
                    close: {
                        img: CloseIcon,
                        name: 'default',
                    }
                },
                showTypingIndicator: '',
                alwaysScrollToBottom: true,
                messageStyling: true
            };
        },
        computed: {
          // mix the getters into computed with object spread operator
          ...mapGetters([
            'isChatOpen',
          ]),
          titleImageUrl() {
            return this.siteConfig.titleImageUrl;
          },
          colors() {
            return {
              header: {
                bg: this.siteConfig.colorHeaderBg,
                text: this.siteConfig.colorHeaderText
              },
              launcher: {
                bg: this.siteConfig.colorLauncherBg
              },
              messageList: {
                bg: this.siteConfig.colorMessageListBg // TODO add conversationList colors to config
              },
              sentMessage: {
                bg: this.siteConfig.colorSentMessageBg,
                text: this.siteConfig.colorSentMessageText
              },
              receivedMessage: {
                bg: this.siteConfig.colorReceivedMessageBg,
                text: this.siteConfig.colorReceivedMessageText
              },
              userInput: {
                bg: this.siteConfig.colorUserInputBg,
                text: this.siteConfig.colorUserInputText
              }
            };
          },
          participants() {
              if (this.internalMode && this.contacts && this.contacts.length > 0) {
                  return this.contacts.map(c => {
                      return {
                          id: c, // TODO username
                          name: c, // TODO displayName
                          imageUrl: this.siteConfig.participantImageUrl
                      };
                  });
              }
              return [
                  {
                      id: this.siteConfig.participantId,
                      name: this.siteConfig.participantName,
                      imageUrl: this.siteConfig.participantImageUrl
                  }
              ];
          }
        },
        methods: {
          ...mapMutations([
            'setIsChatOpen',
            'setCurrentConversation',
            'setCurrentConversationMessages',
            'incrementInteractiveCount',
          ]),
          ...mapActions([
            'sendMessage',
            'sendGetConversations',
            'sendGetWelcomeNotification',
          ]),
          openChat() {
              this.incrementInteractiveCount();
              this.setIsChatOpen(true);
          },
          closeChat() {
              this.incrementInteractiveCount();
              this.setIsChatOpen(false);
              this.setCurrentConversation(null);
              this.setCurrentConversationMessages([]);
              this.sendGetConversations();
          },
          handleOnType() {
              // triggers every time the user types into the chatbox
          }
        }
    };
</script>

<style scoped>

</style>
