export default [
  {
    name: 'People',
    emojis: [
      '😄',
      '😃',
      '😀',
      '😊',
      '😉',
      '😍',
      '😘',
      '😚',
      '😗',
      '😙',
      '😜',
      '😝',
      '😛',
      '😳',
      '😁',
      '😔',
      '😌',
      '😒',
      '😞',
      '😣',
      '😢',
      '😂',
      '😭',
      '😪',
      '😥',
      '😰',
      '😅',
      '😓',
      '😩',
      '😫',
      '😨',
      '😱',
      '😠',
      '😡',
      '😤',
      '😖',
      '😆',
      '😋',
      '😷',
      '😎',
      '😴',
      '😵',
      '😲',
      '😟',
      '😦',
      '😧',
      '👿',
      '😮',
      '😬',
      '😐',
      '😕',
      '😯',
      '😏',
      '😑',
      '👲',
      '👳',
      '👮',
      '👷',
      '💂',
      '👶',
      '👦',
      '👧',
      '👨',
      '👩',
      '👴',
      '👵',
      '👱',
      '👼',
      '👸',
      '😺',
      '😸',
      '😻',
      '😽',
      '😼',
      '🙀',
      '😿',
      '😹',
      '😾',
      '👹',
      '👺',
      '🙈',
      '🙉',
      '🙊',
      '💀',
      '👽',
      '💩',
      '🔥',
      '✨',
      '🌟',
      '💫',
      '💥',
      '💢',
      '💦',
      '💧',
      '💤',
      '💨',
      '👂',
      '👀',
      '👃',
      '👅',
      '👄',
      '👍',
      '👎',
      '👌',
      '👊',
      '✊',
      '👋',
      '✋',
      '👐',
      '👆',
      '👇',
      '👉',
      '👈',
      '🙌',
      '🙏',
      '👏',
      '💪',
      '🚶',
      '🏃',
      '💃',
      '👫',
      '👪',
      '💏',
      '💑',
      '👯',
      '🙆',
      '🙅',
      '💁',
      '🙋',
      '💆',
      '💇',
      '💅',
      '👰',
      '🙎',
      '🙍',
      '🙇',
      '🎩',
      '👑',
      '👒',
      '👟',
      '👞',
      '👡',
      '👠',
      '👢',
      '👕',
      '👔',
      '👚',
      '👗',
      '🎽',
      '👖',
      '👘',
      '👙',
      '💼',
      '👜',
      '👝',
      '👛',
      '👓',
      '🎀',
      '🌂',
      '💄',
      '💛',
      '💙',
      '💜',
      '💚',
      '💔',
      '💗',
      '💓',
      '💕',
      '💖',
      '💞',
      '💘',
      '💌',
      '💋',
      '💍',
      '💎',
      '👤',
      '💬',
      '👣'
    ]
  },
  {
    name: 'Nature',
    emojis: [
      '🐶',
      '🐺',
      '🐱',
      '🐭',
      '🐹',
      '🐰',
      '🐸',
      '🐯',
      '🐨',
      '🐻',
      '🐷',
      '🐽',
      '🐮',
      '🐗',
      '🐵',
      '🐒',
      '🐴',
      '🐑',
      '🐘',
      '🐼',
      '🐧',
      '🐦',
      '🐤',
      '🐥',
      '🐣',
      '🐔',
      '🐍',
      '🐢',
      '🐛',
      '🐝',
      '🐜',
      '🐞',
      '🐌',
      '🐙',
      '🐚',
      '🐠',
      '🐟',
      '🐬',
      '🐳',
      '🐎',
      '🐲',
      '🐡',
      '🐫',
      '🐩',
      '🐾',
      '💐',
      '🌸',
      '🌷',
      '🍀',
      '🌹',
      '🌻',
      '🌺',
      '🍁',
      '🍃',
      '🍂',
      '🌿',
      '🌾',
      '🍄',
      '🌵',
      '🌴',
      '🌰',
      '🌱',
      '🌼',
      '🌑',
      '🌓',
      '🌔',
      '🌕',
      '🌛',
      '🌙',
      '🌏',
      '🌋',
      '🌌',
      '🌠',
      '⛅',
      '⛄',
      '🌀',
      '🌁',
      '🌈',
      '🌊'

    ]
  },
  {
    name: 'Objects',
    emojis: [
      '🎍',
      '💝',
      '🎎',
      '🎒',
      '🎓',
      '🎏',
      '🎆',
      '🎇',
      '🎐',
      '🎑',
      '🎃',
      '👻',
      '🎅',
      '🎄',
      '🎁',
      '🎋',
      '🎉',
      '🎊',
      '🎈',
      '🎌',
      '🔮',
      '🎥',
      '📷',
      '📹',
      '📼',
      '💿',
      '📀',
      '💽',
      '💾',
      '💻',
      '📱',
      '📞',
      '📟',
      '📠',
      '📡',
      '📺',
      '📻',
      '🔊',
      '🔔',
      '📢',
      '📣',
      '⏳',
      '⌛',
      '⏰',
      '⌚',
      '🔓',
      '🔒',
      '🔏',
      '🔐',
      '🔑',
      '🔎',
      '💡',
      '🔦',
      '🔌',
      '🔋',
      '🔍',
      '🛀',
      '🚽',
      '🔧',
      '🔩',
      '🔨',
      '🚪',
      '🚬',
      '💣',
      '🔫',
      '🔪',
      '💊',
      '💉',
      '💰',
      '💴',
      '💵',
      '💳',
      '💸',
      '📲',
      '📧',
      '📥',
      '📤',
      '📩',
      '📨',
      '📫',
      '📪',
      '📮',
      '📦',
      '📝',
      '📄',
      '📃',
      '📑',
      '📊',
      '📈',
      '📉',
      '📜',
      '📋',
      '📅',
      '📆',
      '📇',
      '📁',
      '📂',
      '📌',
      '📎',
      '📏',
      '📐',
      '📕',
      '📗',
      '📘',
      '📙',
      '📓',
      '📔',
      '📒',
      '📚',
      '📖',
      '🔖',
      '📛',
      '📰',
      '🎨',
      '🎬',
      '🎤',
      '🎧',
      '🎼',
      '🎵',
      '🎶',
      '🎹',
      '🎻',
      '🎺',
      '🎷',
      '🎸',
      '👾',
      '🎮',
      '🃏',
      '🎴',
      '🀄',
      '🎲',
      '🎯',
      '🏈',
      '🏀',
      '⚽',
      '⚾',
      '🎾',
      '🎱',
      '🎳',
      '⛳',
      '🏁',
      '🏆',
      '🎿',
      '🏂',
      '🏊',
      '🏄',
      '🎣',
      '🍵',
      '🍶',
      '🍺',
      '🍻',
      '🍸',
      '🍹',
      '🍷',
      '🍴',
      '🍕',
      '🍔',
      '🍟',
      '🍗',
      '🍖',
      '🍝',
      '🍛',
      '🍤',
      '🍱',
      '🍣',
      '🍥',
      '🍙',
      '🍘',
      '🍚',
      '🍜',
      '🍲',
      '🍢',
      '🍡',
      '🍳',
      '🍞',
      '🍩',
      '🍮',
      '🍦',
      '🍨',
      '🍧',
      '🎂',
      '🍰',
      '🍪',
      '🍫',
      '🍬',
      '🍭',
      '🍯',
      '🍎',
      '🍏',
      '🍊',
      '🍒',
      '🍇',
      '🍉',
      '🍓',
      '🍑',
      '🍈',
      '🍌',
      '🍍',
      '🍠',
      '🍆',
      '🍅',
      '🌽'
    ]
  },
  {
    name: 'Places',
    emojis: [
      '🏠',
      '🏡',
      '🏫',
      '🏢',
      '🏣',
      '🏥',
      '🏦',
      '🏪',
      '🏩',
      '🏨',
      '💒',
      '⛪',
      '🏬',
      '🌇',
      '🌆',
      '🏯',
      '🏰',
      '⛺',
      '🏭',
      '🗼',
      '🗾',
      '🗻',
      '🌄',
      '🌅',
      '🌃',
      '🗽',
      '🌉',
      '🎠',
      '🎡',
      '⛲',
      '🎢',
      '🚢',
      '⛵',
      '🚤',
      '🚀',
      '💺',
      '🚉',
      '🚄',
      '🚅',
      '🚇',
      '🚃',
      '🚌',
      '🚙',
      '🚗',
      '🚕',
      '🚚',
      '🚨',
      '🚓',
      '🚒',
      '🚑',
      '🚲',
      '💈',
      '🚏',
      '🎫',
      '🚥',
      '🚧',
      '🔰',
      '⛽',
      '🏮',
      '🎰',
      '🗿',
      '🎪',
      '🎭',
      '📍',
      '🚩'
    ]
  },
  {
    name: 'Symbols',
    emojis: [
      '🔟',
      '🔢',
      '🔣',
      '🔠',
      '🔡',
      '🔤',
      '🔼',
      '🔽',
      '⏪',
      '⏩',
      '⏫',
      '⏬',
      '🆗',
      '🆕',
      '🆙',
      '🆒',
      '🆓',
      '🆖',
      '📶',
      '🎦',
      '🈁',
      '🈯',
      '🈳',
      '🈵',
      '🈴',
      '🈲',
      '🉐',
      '🈹',
      '🈺',
      '🈶',
      '🈚',
      '🚻',
      '🚹',
      '🚺',
      '🚼',
      '🚾',
      '🚭',
      '🈸',
      '🉑',
      '🆑',
      '🆘',
      '🆔',
      '🚫',
      '🔞',
      '⛔',
      '❎',
      '✅',
      '💟',
      '🆚',
      '📳',
      '📴',
      '🆎',
      '💠',
      '⛎',
      '🔯',
      '🏧',
      '💹',
      '💲',
      '💱',
      '❌',
      '❗',
      '❓',
      '❕',
      '❔',
      '⭕',
      '🔝',
      '🔚',
      '🔙',
      '🔛',
      '🔜',
      '🔃',
      '🕛',
      '🕐',
      '🕑',
      '🕒',
      '🕓',
      '🕔',
      '🕕',
      '🕖',
      '🕗',
      '🕘',
      '🕙',
      '🕚',
      '➕',
      '➖',
      '➗',
      '💮',
      '💯',
      '🔘',
      '🔗',
      '➰',
      '🔱',
      '🔺',
      '🔲',
      '🔳',
      '🔴',
      '🔵',
      '🔻',
      '⬜',
      '⬛',
      '🔶',
      '🔷',
      '🔸',
      '🔹'
    ]
  }
];
