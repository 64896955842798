import Vuex from 'vuex';
import Vue from 'vue';

Vue.use(Vuex);

function uuidv4() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

function getDateObjectFromTimeString(timeString) {
  // if time start with negative the day is set to off
  if (timeString.startsWith('-')) {
    return new Date(-1);
  }

  // NB: when retrieving stored time or saving time we must use UTC time
  const date = new Date();
  date.setUTCHours(Number.parseInt(timeString.split(':')[0]));
  date.setUTCMinutes(Number.parseInt(timeString.split(':')[1]));
  date.setUTCSeconds(Number.parseInt(timeString.split(':')[2]));
  return date;
}


function checkIfChatIsEnabledOnDay(now, openingHourString, closingHourString) {
  const openingHour = getDateObjectFromTimeString(openingHourString);
  const closingHour = getDateObjectFromTimeString(closingHourString);

  if (openingHour.valueOf() === -1 && closingHour.valueOf() === -1) {
    // day is turned off
    return false;
  }

  if (openingHour.valueOf() === closingHour.valueOf()) {
    // if opening and closing hour is the same then this means it a 24 hour
    // active day
    return true;
  }

  return openingHour.valueOf() <= now.valueOf() && now.valueOf() <= closingHour.valueOf();
}

/**
 * Check current time against the schedule.
 * @param schedule
 * @return true if chat is enabled according the schedule, false if not
 */
function checkIfChatEnabledAgainstSchedule(schedule) {
  const now = new Date();
  const day = now.getDay(); // 0 is sunday - 6 is saturday

  if (day === 0) {
    return checkIfChatIsEnabledOnDay(now, schedule.sunOpeningHour, schedule.sunClosingHour);
  }

  if (day === 1) {
    return checkIfChatIsEnabledOnDay(now, schedule.monOpeningHour, schedule.monClosingHour);
  }

  if (day === 2) {
    return checkIfChatIsEnabledOnDay(now, schedule.tueOpeningHour, schedule.tueClosingHour);
  }

  if (day === 3) {
    return checkIfChatIsEnabledOnDay(now, schedule.wedOpeningHour, schedule.wedClosingHour);
  }

  if (day === 4) {
    return checkIfChatIsEnabledOnDay(now, schedule.thuOpeningHour, schedule.thuClosingHour);
  }

  if (day === 5) {
    return checkIfChatIsEnabledOnDay(now, schedule.friOpeningHour, schedule.friClosingHour);
  }

  if (day === 6) {
    return checkIfChatIsEnabledOnDay(now, schedule.satOpeningHour, schedule.satClosingHour);
  }

  return true;
}

// TODO: create enum for accepted messages
// const WEB_SOCKET_MESSAGES = {
//   MY_CHAT_CONFIG: 'MY_CHAT_CONFIG',
//   MY_CHAT_CONFIG_OK: 'MY_CHAT_CONFIG_OK',
//   MY_CHAT_CONFIG_ERROR: 'MY_CHAT_CONFIG_ERROR',
// };

export default new Vuex.Store({
  // DO NOT DIRECTLY RETRIEVE OR CHANGE DATA FROM THE STATE OBJECT.
  // USE THE GETTERS AND SETTERS FOR THIS.
  state: {
    // Needed a counter to force a refresh of the conversation list relative timestamps
    // if the data does not change the computed values in the list do not change
    // by increasing this value each time we enter the conversation list we will
    // get a new relative timestamp
    interactiveCount: 0,
    internalMode: false,
    internalTmgAgentUsername: null,
    internalTmgAgentContacts: [],
    chatEnabledOnConfig: false,
    chatEnabledOnSchedule: false,
    chatEnabledOnSetup: false,
    isChatOpen: false,
    authorUUID: null,
    editMessage: null,
    currentConversation: null, // TODO: change to name currentParticipant
    currentConversationMessages: [],
    conversations: [],
    siteId: null,
    socket: null,
    pingIntervalId: null,
    scheduleCheckIntervalId: null,
    isChatClosed: true,
    ///////////////////////////////////////////////
    // Information about siteConfig
    // siteConfig: {
    //   siteId: null,
    //   participantId: null,
    //   participantName: null,
    //   participantImageUrl: null,
    //   titleImageUrl: null,
    //   colorHeaderBg: null,
    //   colorHeaderText: null,
    //   colorLauncherBg: null,
    //   colorMessageListBg: null,
    //   colorSentMessageBg: null,
    //   colorSentMessageText: null,
    //   colorReceivedMessageBg: null,
    //   colorReceivedMessageText: null,
    //   colorUserInputBg: null,
    //   colorUserInputText: null,
    //   isEnabled: true
    // }
    ////////////////////////////////////////////////
    siteConfig: null,
    ////////////////////////////////////////////////
    // Information about siteSchedule
    ////////////////////////////////////////////////
    siteSchedule: null,
    welcomeNotification: null,
  },

  getters: {
    interactiveCount(state) {
      return state.interactiveCount;
    },
    internalMode(state) {
      return state.internalMode;
    },
    internalTmgAgentUsername(state) {
      return state.internalTmgAgentUsername;
    },
    internalTmgAgentContacts(state) {
      return state.internalTmgAgentContacts;
    },
    externalGroupId(state) {
      return state.internalMode ? 'INTERNAL_CHAT' : state.siteId;
    },
    chatEnabled(state) {
      return state.chatEnabledOnConfig && state.chatEnabledOnSchedule && state.chatEnabledOnSetup;
    },
    isChatOpen(state) {
      return state.isChatOpen;
    },
    newMessagesCount(state) {
      //if popupEnabled on site on new notification the chat pops up
      let count = 0;
      state.conversations.forEach(conversation => {
        if (conversation.lastMessage) {
          if (!conversation.lastMessage.isRead
              && conversation.lastMessage.author.uuid !== state.authorUUID) {
            if (state.siteConfig.popupEnabled && state.isChatClosed) {
              state.isChatOpen = true;
              state.isChatClosed = false;
            }
            count++;
          }
        }
      });
      return count;
    },
    authorUUID(state) {
      return state.authorUUID;
    },
    editMessage(state) {
      return state.editMessage;
    },
    currentConversation(state) { // TODO: change to name currentParticipant
      return state.currentConversation;
    },
    currentConversationMessages(state) {
      return state.currentConversationMessages;
    },
    conversations(state) {
      return state.conversations;
    },
    siteId(state) {
      return state.siteId;
    },
    socket(state) {
      return state.socket;
    },
    pingIntervalId(state) {
      return state.pingIntervalId;
    },
    scheduleCheckIntervalId(state) {
      return state.scheduleCheckIntervalId;
    },
    siteConfig(state) {
      return state.siteConfig;
    },
    siteSchedule(state) {
      return state.siteSchedule;
    },
    welcomeNotification(state) {
      return state.welcomeNotification;
    }
  },

  mutations: {
    incrementInteractiveCount(state) {
      state.interactiveCount += 1;
    },
    setInternalMode(state, value) {
      state.internalMode = value;
    },
    setInternalTmgAgentUsername(state, value) {
      state.internalTmgAgentUsername = value;
    },
    setInternalTmgAgentContacts(state, value) {
      state.internalTmgAgentContacts = value;
    },
    setChatEnabledOnConfig(state, value) {
      state.chatEnabledOnConfig = value;
    },
    setChatEnabledOnSchedule(state, value) {
      state.chatEnabledOnSchedule = value;
    },
    setChatEnabledOnSetup(state, value) {
      state.chatEnabledOnSetup = value;
    },
    setIsChatOpen(state, value) {
      state.isChatOpen = value;
    },
    setAuthorUUID(state, value) {
      state.authorUUID = value;
    },
    setEditMessage(state, value) {
      state.editMessage = value;
    },
    setSiteId(state, value) {
      state.siteId = value;
    },
    setSocket(state, value) {
      state.socket = value;
    },
    setPingIntervalId(state, value) {
      state.pingIntervalId = value;
    },
    setScheduleCheckIntervalId(state, value) {
      state.scheduleCheckIntervalId = value;
    },
    setSiteConfig(state, value) {
      state.siteConfig = value;
    },
    setCurrentConversation(state, value) { // TODO: change to name currentParticipant
      state.currentConversation = value;
    },
    setCurrentConversationMessages(state, value) {
      state.currentConversationMessages = value;
    },
    setConversations(state, value) {
      state.conversations = value;
    },
    setSiteSchedule(state, value) {
      state.siteSchedule = value;
    },
    setChatPopUp(state, value) {
      if (state.siteConfig.popupEnabled) {
        state.isChatClosed = value;
      }
    }
  },

  actions: {
    initSocket({ commit, dispatch}, socketUrl) {
      const socket = new WebSocket(socketUrl);
      socket.onopen = () => {
        dispatch('socketOpened');
      };
      socket.onmessage = (event) => {
        dispatch('socketReceivedMessage', event);
      };
      socket.onclose = () => {
        dispatch('socketClosed');
      };
      socket.onerror = (event) => {
        // error in socket
      };

      commit('setSocket', socket);
    },
    socketOpened({ commit, dispatch, getters}) {
      const myChatConfigPayload = {
        action: 'MY_CHAT_CONFIG',
        webSocketMessageId: uuidv4(),
        payload: JSON.stringify({siteId: getters.siteId})
      };
      getters.socket.send(JSON.stringify(myChatConfigPayload));

      const getChatSchedulePayload = {
        action: 'GET_CHAT_SCHEDULE',
        webSocketMessageId: uuidv4(),
        payload: JSON.stringify({siteId: getters.siteId})
      };
      getters.socket.send(JSON.stringify(getChatSchedulePayload));

      const intervalId = setInterval(() => {
        dispatch('sendPing');
      }, 5 * 60 * 1000); // Every 5 minutes

      commit('setPingIntervalId', intervalId);
    },
    socketReceivedMessage({ commit, dispatch, getters }, messageEvent) {
      // called when receiving server response
      const data = JSON.parse(messageEvent.data);
      const action = data['action'];
      let payloadString = data['payload'];

      if (!action || !payloadString) {
        // ignoring message with unexpected formatting
        return;
      }

      const payload = JSON.parse(payloadString);

      switch(action) {
        case 'SEND_MESSAGE': // chat has received a new chat message
          if (getters.currentConversation && getters.currentConversation.conversation) {
            // TODO: only do this if the new SEND_MESSAGE conversation is the one im looking at
            dispatch('sendGetConversationMessages');
          } else {
            commit('setChatPopUp', true);
            dispatch('sendGetConversations');
          }
          break;
        case 'SEND_MESSAGE_OK': // chat has sent a chat message and received OK back
          const tmpCurrentConversation = getters.currentConversation;
          tmpCurrentConversation.conversation = payload;
          commit('setCurrentConversation', tmpCurrentConversation);
          dispatch('sendGetConversationMessages');
          break;
        case 'GET_WELCOME_CONTENT_OK':
          if (Object.keys(payload).length === 0) {
            break;
          }
          dispatch('sendGetConversations');
          break;
        case 'CREATE_AUTHOR_OK': // TODO: add description to each case
          const uuid = payload.uuid;
          localStorage.setItem('tmg_authorUUID', uuid);
          commit('setAuthorUUID', uuid);
          dispatch('sendAuthorUUID', uuid);
          break;
        case 'CREATE_AUTHOR_ERROR':
          if (payload.errorType === 'ItemAlreadyExists') {
            // author already exist
            const uuid = payload.errorMessage.split('uuid=')[1].split(',')[0];
            localStorage.setItem('tmg_authorUUID', uuid);
            commit('setAuthorUUID', uuid);
            dispatch('sendAuthorUUID', uuid);
          }
          break;
        case 'MY_AUTHOR_ID_OK':
          dispatch('sendGetConversations');
          dispatch('sendGetWelcomeNotification');
          break;
        case 'MY_AUTHOR_ID_ERROR':
          localStorage.removeItem('tmg_authorUUID');
          commit('setAuthorUUID', null);
          break;
        case 'GET_CONVERSATIONS_OK':
          commit('setConversations', payload);
          commit('setChatEnabledOnSetup', true);
          break;
        case 'GET_CONVERSATION_MESSAGES_OK':
          commit('setCurrentConversationMessages', []);
          const messages = payload.map(m => {
            // TODO change check for authors when we have more than two participants
            m.author = m.author.uuid === getters.authorUUID ? 'me' : 'system';
            m.type = 'text';
            m.data = { text: m.content };
            return m;
          });
          commit('setCurrentConversationMessages', messages);
          commit('setChatEnabledOnSetup', true);
          break;
        case 'MY_CHAT_CONFIG_OK':
          if (!payload.siteId && payload.siteId !== getters.siteId) {
            return;
          }
          commit('setSiteConfig', payload);
          commit('setChatEnabledOnConfig', payload.isEnabled);

          // TODO: what is this logic if agent then null and always create??
            // TODO: if not then always use authuorUUID? shouldn't we always check local storeage first?
          const authorUUID2 = getters.internalTmgAgentUsername ? null :
              localStorage.getItem('tmg_authorUUID');
          if (!authorUUID2) {
            dispatch('sendCreateAuthor');
          } else {
            dispatch('sendAuthorUUID', authorUUID2);
          }
          break;
        case 'READ_MESSAGES_OK':
            dispatch('sendGetConversations');
            break;
        case 'CHAT_CONFIG_CHANGED':
          if (!payload.siteId && payload.siteId !== getters.siteId) {
            return;
          }

          commit('setSiteConfig', payload);
          commit('setChatEnabledOnConfig', payload.isEnabled);
          break;
        case 'GET_CHAT_SCHEDULE_OK':
          if (!payload.siteId && payload.siteId !== getters.siteId) {
            return;
          }

          if (getters.scheduleCheckIntervalId) {
            clearInterval(getters.scheduleCheckIntervalId);
            commit('setScheduleCheckIntervalId', null);
          }

          commit('setSiteSchedule', payload);

          commit('setChatEnabledOnSchedule', checkIfChatEnabledAgainstSchedule(getters.siteSchedule));

          const intervalId = setInterval(() => {
            commit('setChatEnabledOnSchedule', checkIfChatEnabledAgainstSchedule(getters.siteSchedule));
          }, 30 * 1000); // Every 30 seconds

          commit('setScheduleCheckIntervalId', intervalId);
          break;
        case 'GET_CHAT_SCHEDULE_ERROR':
          commit('setChatEnabledOnSchedule', true); // set to always true if we can't find a schedule
          break;
        case 'CHAT_SCHEDULE_CHANGED':
          if (!payload.siteId && payload.siteId !== getters.siteId) {
            return;
          }

          if (getters.scheduleCheckIntervalId) {
            clearInterval(getters.scheduleCheckIntervalId);
            commit('setScheduleCheckIntervalId', null);
          }

          commit('setSiteSchedule', payload);

          commit('setChatEnabledOnSchedule', checkIfChatEnabledAgainstSchedule(getters.siteSchedule));

          const intervalId2 = setInterval(() => {
            commit('setChatEnabledOnSchedule', checkIfChatEnabledAgainstSchedule(getters.siteSchedule));
          }, 30 * 1000); // Every 30 seconds

          commit('setScheduleCheckIntervalId', intervalId2);
          break;
        default: // TODO: add logging on all other messages?
          break;
      }
    },
    socketClosed({ commit, getters }) {
      if (getters.pingIntervalId) {
        clearInterval(getters.pingIntervalId);
        commit('setPingIntervalId', null);
      }

      commit('setChatEnabledOnSetup', false);
    },
    sendPing({ getters }) {
      const payload = {};
      const ping = {
        action: 'PING',
        webSocketMessageId: uuidv4(),
        payload: JSON.stringify(payload)
      };
      getters.socket.send(JSON.stringify(ping));
    },
    sendGetConversations({ getters }) {
      if (!getters.authorUUID) {
        return;
      }

      if (!getters.externalGroupId) {
        return;
      }

      const payload = {
        requestFromAuthor: getters.authorUUID,
        externalGroupId: getters.externalGroupId,
      };

      const body = {
        action: 'GET_CONVERSATIONS',
        webSocketMessageId: uuidv4(),
        payload: JSON.stringify(payload)
      };
      getters.socket.send(JSON.stringify(body));
    },
    sendGetWelcomeNotification({ getters }) {
      if (!getters.authorUUID) {
        return;
      }

      if (!getters.externalGroupId) {
        return;
      }
      const payload = {
        content: '',
        author: getters.authorUUID,
        attachments: [], // TODO: we currently do not support attachments due to AWS webSocket payload size limitations
        type: 'TMG_CHAT',
        externalGroupId: getters.externalGroupId,
        metaData: [], // TODO add webpage url here so we can know from where they are talking?
      };

      if (getters.currentConversation && getters.currentConversation.conversation) {
        // conversation already exists
        return;
      } else {
        // new conversation, must set a receiver when starting a new conversation
        payload.receivers = [getters.internalMode ? getters.currentConversation.participant.id : getters.siteId];
        payload.receivers.push(getters.authorUUID);
      }
      const body = {
        action: 'GET_WELCOME_CONTENT',
        webSocketMessageId: uuidv4(),
        payload: JSON.stringify(payload)
      };

      getters.socket.send(JSON.stringify(body));

    },

    sendGetConversationMessages({ getters }) {
      if (!getters.authorUUID) {
        return;
      }
      if (!getters.currentConversation.conversation) {
        return;
      }

      const payload = {
        requestFromAuthor: getters.authorUUID,
        conversationUUID: getters.currentConversation.conversation.uuid
      };
      const body = {
        action: 'GET_CONVERSATION_MESSAGES',
        webSocketMessageId: uuidv4(),
        payload: JSON.stringify(payload)
      };
      getters.socket.send(JSON.stringify(body));
    },
    sendMessage({ commit, getters }, message) {
      if (message.type === 'file') {
        // TODO: message contains an attachment. upload it.
        // TODO: how to display it after sending, and when retrieving all previous messages in conversation?
        return;
      }

      const payload = {
        content: message.data.text,
        author: getters.authorUUID,
        attachments: [], // TODO: we currently do not support attachments due to AWS webSocket payload size limitations
        type: 'TMG_CHAT',
        externalGroupId: getters.externalGroupId,
        metaData: [] // TODO add webpage url here so we can know from where they are talking?
      };

    if (getters.currentConversation && getters.currentConversation.conversation) {
        // conversation already exists and this is a reply
        payload.conversationUUID = getters.currentConversation.conversation.uuid;
      } else {
        // new conversation, must set a receiver when starting a new conversation
        payload.receivers = [getters.internalMode ? getters.currentConversation.participant.id : getters.siteId];
      }

      const body = {
        action: 'SEND_MESSAGE',
        webSocketMessageId: uuidv4(),
        payload: JSON.stringify(payload)
      };
      getters.socket.send(JSON.stringify(body));
    },
    sendAuthorUUID({ commit, getters }, authorUUID) {
      commit('setAuthorUUID', authorUUID);
      const request = {
        action: 'MY_AUTHOR_ID',
        webSocketMessageId: uuidv4(),
        payload: JSON.stringify({uuid: authorUUID})
      };
      getters.socket.send(JSON.stringify(request));
    },

    sendCreateAuthor({ getters }) {
      const authorName = getters.internalTmgAgentUsername ? getters.internalTmgAgentUsername : uuidv4();
      const author = {
        username: authorName,
        displayName: authorName,
        type: 'LEAD',
        savedBy: 'chatClient'
      };

      const body = {
        action: 'CREATE_AUTHOR',
        webSocketMessageId: uuidv4(),
        payload: JSON.stringify(author)
      };
      getters.socket.send(JSON.stringify(body));
    },

    sendCurrentConversationMessagesIsRead({ getters }) {
      // TODO: deconstruct this IF
      if (getters.currentConversation && getters.currentConversation.conversation
          && getters.currentConversation.conversation.lastMessage) {
        if (getters.currentConversation.conversation.lastMessage.author.uuid === getters.authorUUID) {
          return;
        }

        const payload = {
          conversationUUID: getters.currentConversation.conversation.uuid,
          timestamp: new Date().toISOString()
        };
        const body = {
          action: 'READ_MESSAGES',
          webSocketMessageId: uuidv4(),
          payload: JSON.stringify(payload)
        };
        getters.socket.send(JSON.stringify(body));
      }
    },
  }
});
