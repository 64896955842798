<template>
  <div class="user-list">
    <div class="search-container">
      <input class="conversation-search" v-model="conversationSearch" type="text" placeholder="Search...">
    </div>
    <div class="participant"
         @click="participantConversationSelected(participantConversation)"
         v-for="participantConversation in participantConversations"
         :key="participantConversation.participant.id">
      <div class="user-image">
        <img :src="siteConfig.participantImageUrl" class="img"/>
      </div>
      <div class="user-element">
        <div class="user-name" style="display: table">
          <span :class="getUnreadMessageClass(participantConversation)" style="display: table-cell; width: 80%;">
            {{getParticipantConversationName(participantConversation)}}
          </span>
          <span :class="getUnreadMessageClass(participantConversation)" style="display: table-cell" class="user-timestamp">
            {{getParticipantConversationTimestamp(participantConversation)}}
             <img v-if="isParticipantConversationUnread(participantConversation)"
                  class="red-circle-alert red-circle-alert-color" :src="icons.circle.img" :alt="icons.circle.name"/>
          </span>
        </div>
        <div :class="getUnreadMessageClass(participantConversation)"
             class="conversation-preview">{{getParticipantConversationMessage(participantConversation)}}</div>
      </div>
    </div>
  </div>
</template>
<script>
  import { formatDistance, parseISO, isBefore } from 'date-fns';
  import {mapActions, mapGetters, mapMutations} from 'vuex';
  import Circle from './assets/circle-solid.svg';

  export default {
    data() {
      return {
        conversationSearch: '',
        icons:   {
          circle:{
            img: Circle,
            name: 'circle',
          }
        },
      };
    },
    props: {
      participants: {
        type: Array,
        required: true
      },
      currentAgent: {
        type: String,
        required: false
      }
    },
    computed: {
      // mix the getters into computed with object spread operator
      ...mapGetters([
        'conversations',
        'externalGroupId',
        'authorUUID',
        'internalMode',
        'interactiveCount',
        'siteConfig'
      ]),
      participantConversations() {
        // leave this be. You can find the reason why described
        // in the store
        const count = this.interactiveCount;
        const tmpParticipants = this.participants
            .filter(p => !this.conversationSearch
                || p.id.toLowerCase().includes(this.conversationSearch.toLowerCase())
                || p.name.toLowerCase().includes(this.conversationSearch.toLowerCase()));

        const tmpConversations = this.conversations;
        // TODO: this currently only finds the first conversation with the participants.
        // TODO: We should join all messages from all conversations to a single thread
        // TODO: sorting the conversations on UUID so we at least always see the same conversations on both sides
        // TODO: of the chat
        const sortedTmpConversations = tmpConversations.sort((a, b) => {
          if (!b.uuid) {
            return 1;
          }

          if (!a.uuid) {
            return -1;
          }

          return a.uuid.localeCompare(b.uuid);
        });

        const tmpParticipants1 = tmpParticipants.map(p => {
          return {
            participant: p,
            conversation: sortedTmpConversations.find(c =>
                // TODO: rewrite this find into a compare functions
                (!this.internalMode && this.externalGroupId === c.externalGroupId)
                || c.receivers.find(r => r.username === p.id)
              || c.source.username === p.id
            ) || null
          };
        });

        return tmpParticipants1.sort((a, b) => {
          if (!a.conversation) {
            return 1;
          }
          if (!b.conversation) {
            return -1;
          }
          return isBefore(parseISO(a.conversation.lastMessage.firstCreatedAtUtc),
              parseISO(b.conversation.lastMessage.firstCreatedAtUtc)) ? 1 : -1;
        });
      }
    },
    methods: {
      ...mapMutations([
        'setCurrentConversation',
      ]),
      ...mapActions([
        'sendGetConversationMessages',
        'sendCurrentConversationMessagesIsRead',
      ]),
      async participantConversationSelected(participantConversation) {
        this.setCurrentConversation(participantConversation);
        await this.sendGetConversationMessages();
        await this.sendCurrentConversationMessagesIsRead();
      },
      getParticipantConversationName(participantConversation) {
        return participantConversation.participant.name || participantConversation.participant.id;
      },
      getParticipantConversationMessage(participantConversation) {
        return participantConversation.conversation ?
          participantConversation.conversation.lastMessage.content : '';
      },
      getParticipantConversationTimestamp(participantConversation) {
        if (participantConversation.conversation) {
          const lastMessageDate = parseISO(participantConversation.conversation.lastMessage.createdAtUtc);
          // TODO: add local later on
          return formatDistance(new Date(), lastMessageDate) + ' ago';
        }
        return '--:--:--';
      },
      isParticipantConversationUnread({ conversation }) {
        if (conversation && conversation.lastMessage) {
          return !conversation.lastMessage.isRead
              && conversation.lastMessage.author.uuid !== this.authorUUID;
        }
      },
      getUnreadMessageClass(participantConversation) {
        if (this.isParticipantConversationUnread(participantConversation)) {
          return 'unread-message';
        }
        return '';
      }
    }
  };
</script>
<style scoped>

  /* CHAT-WINDOW-WIDTH: width: 370px; */

  .participant {
    padding-top: 5px;
    width: 100%;
    border-radius: 10px;
    display: table;
    cursor: pointer;
  }

  .participant:hover {
    background-color: #eeeeee;
  }

  .unread-message {
    font-weight: bold;
  }

  .user-image {
    display: table-cell;
    padding: 8px 10px 8px 15px;
  }

  .img {
    border-radius: 50%;
    width: 40px;
  }

  .user-list {
    height: 100%;
    overflow: auto;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 8px;
  }

  .user-element {
    vertical-align: middle;
    display: table-cell;
  }

  .user-name {
    font-size: 20px;
  }

  .user-timestamp {
    width: 50px;
    display: table-cell;
    vertical-align: top;
    padding-top: 5px;
    padding-right: 10px;
    text-align: center;
    font-size: 10px;
    white-space: nowrap;
  }

  .conversation-preview {
    width: 250px;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    margin-top: 5px;
  }

  .conversation-preview-new {
    color: gray;
  }

  .conversation-element:hover {
    background-color: mediumpurple;
  }

  .search-container {
    text-align: center;
  }

  .conversation-search {
    width: 350px; /*355px*/
    height: 30px;

    border: #eeeeee solid 1px;
    border-radius: 10px;
    padding-left: 5px;
    font-size: 17px;
  }

  input:focus, textarea:focus, select:focus{
    outline: none;
  }

  .red-circle-alert {
    align-self: center;
    width: 8px;
    height: 8px;
    cursor: pointer;
  }

  .red-circle-alert-color {
    filter: invert(34%) sepia(90%) saturate(6877%) hue-rotate(353deg) brightness(87%) contrast(109%);
  }

</style>
