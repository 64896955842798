<template>
  <div class="sc-chat-window" :class="{opened: isOpen, closed: !isOpen}" style="z-index: 1000;">
    <Header
      :showCloseButton="showCloseButton"
      :title="title"
      :imageUrl="titleImageUrl"
      :onClose="onClose"
      :colors="colors"
      :disableConversationListToggle="disableConversationListToggle"
      :conversation-participant="currentConversationParticipant"
      @back-to-conversation-list="handleBackToConversationList"
    >
      <template>
        <slot name="header">
        </slot>
      </template>
    </Header>

    <ConversationList
      v-if="!currentConversation"
      :participants="participants"
      :current-agent="currentAgent"
    />
    <MessageList
      v-if="currentConversation"
      :messages="currentConversationMessages"
      :participants="participants"
      :showTypingIndicator="showTypingIndicator"
      :colors="colors"
      :alwaysScrollToBottom="alwaysScrollToBottom"
      :messageStyling="messageStyling"
      @scrollToTop="$emit('scrollToTop')"
      @remove="$emit('remove', $event)"
    >
      <template v-slot:user-avatar="scopedProps">
        <slot name="user-avatar" :user="scopedProps.user" :message="scopedProps.message">
        </slot>
      </template>
      <template v-slot:text-message-body="scopedProps">
        <slot name="text-message-body" :message="scopedProps.message" :messageText="scopedProps.messageText" :messageColors="scopedProps.messageColors" :me="scopedProps.me">
        </slot>
      </template>
      <template v-slot:system-message-body="scopedProps">
        <slot name="system-message-body" :message="scopedProps.message">
        </slot>
      </template>
      <template v-slot:text-message-toolbox="scopedProps">
        <slot name="text-message-toolbox" :message="scopedProps.message" :me="scopedProps.me">
        </slot>
      </template>
    </MessageList>
    <UserInput
      v-if="currentConversation"
      :showEmoji="showEmoji"
      :onSubmit="onUserInputSubmit"
      :suggestions="suggestions"
      :showFile="showFile"
      :placeholder="placeholder"
      @onType="$emit('onType')"
      :colors="colors" />
  </div>
</template>

<script>
import Header from './Header.vue';
import MessageList from './MessageList.vue';
import UserInput from './UserInput.vue';
import ConversationList from './ConversationList.vue';
import {mapActions, mapGetters, mapMutations} from 'vuex';

export default {
  components: {
    Header,
    MessageList,
    UserInput,
    ConversationList
  },
  props: {
    showEmoji: {
      type: Boolean,
      default: false
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    showFile: {
      type: Boolean,
      default: false
    },
    participants: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    titleImageUrl: {
      type: String,
      default: ''
    },
    onUserInputSubmit: {
      type: Function,
      required: true
    },
    onClose: {
      type: Function,
      required: true
    },
    isOpen: {
      type: Boolean,
      default: () => false
    },
    placeholder: {
      type: String,
      default: 'Write a message...'
    },
    showTypingIndicator: {
      type: String,
      required: true
    },
    colors: {
      type: Object,
      required: true
    },
    alwaysScrollToBottom: {
      type: Boolean,
      required: true
    },
    messageStyling: {
      type: Boolean,
      required: true
    },
    disableConversationListToggle: {
      type: Boolean,
      default: false
    },
    internalMode: {
      type: Boolean,
      default: false
    },
    currentAgent: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // showConversationList: true
    };
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
      'currentConversation',
      'currentConversationMessages',
    ]),
    currentConversationParticipant() {
      if (this.currentConversation) {
        return this.currentConversation.participant;
      } else {
        return null;
      }
    },
    suggestions(){
      const messagesLength = this.currentConversationMessages.length;
      return this.currentConversationMessages.length > 0 ?
          this.currentConversationMessages[messagesLength - 1].suggestions : [];
    },
  },
  methods: {
    ...mapMutations([
      'setCurrentConversation',
      'setCurrentConversationMessages',
      'incrementInteractiveCount',
    ]),
    ...mapActions([
      'sendGetConversations',
      'sendGetWelcomeNotification',
    ]),
    async handleBackToConversationList() {
      this.incrementInteractiveCount();
      this.setCurrentConversation(null);
      await this.sendGetWelcomeNotification();
      await this.sendGetConversations();
      this.setCurrentConversationMessages([]);
    },
  }
};
</script>
<style scoped>
.sc-chat-window {
  width: 370px;
  height: calc(100% - 120px);
  max-height: 590px;
  position: fixed;
  right: 25px;
  bottom: 100px;
  box-sizing: border-box;
  box-shadow: 0px 7px 40px 2px rgba(148, 149, 150, 0.1);
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  animation: fadeIn;
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
}

.sc-chat-window.closed {
  opacity: 0;
  display: none;
  bottom: 90px;
}

@keyframes fadeIn {
  0% {
    display: none;
    opacity: 0;
  }

  100% {
    display: flex;
    opacity: 1;
  }
}

.sc-message--me {
  text-align: right;
}
.sc-message--them {
  text-align: left;
}

@media (max-width: 450px) {
  .sc-chat-window {
    width: 100%;
    height: 100%;
    max-height: 100%;
    right: 0px;
    bottom: 0px;
    border-radius: 0px;
  }
  .sc-chat-window {
    transition: 0.1s ease-in-out;
  }
  .sc-chat-window.closed {
    bottom: 0px;
  }
}
</style>
